const EmployeeManagement = [
	{
		path: '/employee-management/employee/list',
		name: 'em-employee-list',
		component: () => import('@/views/employee-management/employee/employee-list/EmployeeList.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/employee-management/attendance/list',
		name: 'em-employee-attendance-list',
		component: () => import('@/views/employee-management/attendance/attendance-list/EmployeeAttendanceList.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/employee-management/application/list',
		name: 'arm-pending-request-list',
		component: () => import('@/views/employee-management/advance/advance-list/EmployeeAdvanceList.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/employee-management/licensee/application/list',
		name: 'arm-licensee-advance-request-list',
		component: () => import('@/views/employee-management/advance/licensee-advance-list/LicenseeAdvanceList.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default EmployeeManagement;