const LicenseeManagement = [

	{
		path: "/licensee-management/licensee/list",
		name: "lm-licensee-list",
		component: () => import("@/views/licensee-management/licensee/licensee-list/LicenseeList.vue"),
		meta: {
			layout: "content",
		},
	}
]

export default LicenseeManagement