const CompanyManagement = [
	{
		path: '/company-management/company-group/list',
		name: 'cm-group-list',
		component: () => import('@/views/company-management/companies/company-group-list/CompanyGroupList.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/company-management/company/list',
		name: 'cm-company-list',
		component: () => import('@/views/company-management/companies/company-list/CompanyList.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/company-management/company/config',
		name: 'cm-company-config',
		component: () => import('@/views/company-management/companies/company-config/CompanyConfigList.vue'),
		meta: {
			layout: 'content',
		},
	},
	// {
	// 	path: '/company-management/users/list',
	// 	name: 'cm-company-users',
	// 	component: () => import('@/views/company-management/users/user-list/CompanyUserList.vue'),
	// 	meta: {
	// 		layout: 'content',
	// 	},
	// }
]

export default CompanyManagement