import {
	mdiAccountOutline,
	mdiCalendarOutline,
	mdiEmailOutline,
	mdiFileDocumentOutline,
	mdiFileOutline,
	mdiMessageTextOutline,
} from "@mdi/js"

export default [
	{
		subheader: "COMPANY MANAGEMENT",
	},
	{
		title: "Company",
		icon: mdiFileDocumentOutline,
		children: [
			{
				title: "Groups",
				to: "cm-group-list",
			},
			{
				title: "List",
				to: "cm-company-list",
			},
			{
				title: "Config",
				to: "cm-company-config",
			}
		]
	}
]
