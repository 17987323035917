import {
	mdiAccountOutline,
	mdiCalendarOutline,
	mdiEmailOutline,
	mdiFileDocumentOutline,
	mdiFileOutline,
	mdiMessageTextOutline,
} from "@mdi/js"

export default [
	{
		subheader: "NOTIFICATION MANAGEMENT",
	},
	{
		title: "Notifications",
		icon: mdiFileDocumentOutline,
		to: "nm-notification-list"
	}
]
