import {
	mdiAccountOutline,
	mdiCalendarOutline,
	mdiEmailOutline,
	mdiFileDocumentOutline,
	mdiFileOutline,
	mdiMessageTextOutline,
} from '@mdi/js'

export default [
	{
		subheader: 'ACCESS MANAGEMENT',
	},
	{
		title: 'Permissions',
		icon: mdiAccountOutline,
		to: 'am-permissions-list'
	},
	{
		title: 'Roles',
		icon: mdiAccountOutline,
		to: 'am-roles-list'
	},
	{
		title: 'Users',
		icon: mdiAccountOutline,
		to: 'am-admins-list'
	}
]
