import {
	mdiAccountOutline,
	mdiCalendarOutline,
	mdiEmailOutline,
	mdiFileDocumentOutline,
	mdiFileOutline,
	mdiMessageTextOutline,
} from "@mdi/js"

export default [
	{
		subheader: "ADVANCE REQUEST MANAGEMENT",
	},
	{
		title: "Advance Requests",
		icon: mdiFileDocumentOutline,
		children: [
			{
				title: "Pending List",
				to: "arm-pending-request-list",
			},
			{
				title: "Licensee Pending List",
				to: "arm-licensee-advance-request-list",
			},
			{
				title: "All Advance Requests",
				to: "arm-all-advance-request-list",
			}
		]
	}
]
