import {
	mdiAccountOutline,
	mdiCalendarOutline,
	mdiEmailOutline,
	mdiFileDocumentOutline,
	mdiFileOutline,
	mdiMessageTextOutline,
} from "@mdi/js"

export default [
	{
		subheader: "EMPLOYEE MANAGEMENT",
	},
	{
		title: "Employees",
		icon: mdiFileDocumentOutline,
		children: [
			{
				title: "List",
				to: "em-employee-list",
			},

			{
				title: "Bulk Upload",
				to: "em-employee-bulk-upload",
			}
		]
	},
	{
		title: "Attendance",
		icon: mdiFileDocumentOutline,
		children: [
			{
				title: "List",
				to: "em-employee-attendance-list",
			},

			{
				title: "Bulk Upload",
				to: "em-employee-attendance-bulk-upload",
			}
		]
	},
]
