const Users = [

	{
		path: '/access-management/admins/list',
		name: 'am-admins-list',
		component: () => import('@/views/access-management/admins/admin-list/AdminList.vue'),
		meta: {
			layout: 'content',
		},
	}
]

export default Users;