const RolesAndPermissions = [
	{
		path: '/access-management/permissions/list',
		name: 'am-permissions-list',
		component: () => import('@/views/access-management/permissions/permission-list/PermissionList.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/access-management/roles/list',
		name: 'am-roles-list',
		component: () => import('@/views/access-management/roles/role-list/RoleList.vue'),
		meta: {
			layout: 'content',
		},
	}
]

export default RolesAndPermissions
