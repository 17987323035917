import {
	mdiAccountOutline,
	mdiCalendarOutline,
	mdiEmailOutline,
	mdiFileDocumentOutline,
	mdiFileOutline,
	mdiMessageTextOutline,
} from "@mdi/js"

export default [
	{
		subheader: "LICENSEE MANAGEMENT",
	},
	{
		title: "Licensees",
		icon: mdiFileDocumentOutline,
		to: "lm-licensee-list"
	}
]
